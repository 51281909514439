<template>
  <MyPageNavigation
    :item-list="navigationList"
    :navigation-heading="type === 'salestool' ? 'mypage.sales.headline':'mypage.headline'"
    :selected="selected"
    :is-dropdown="isDropdown"
    @show="setShowPage"
  />
</template>

<script setup lang="ts">
import MyPageNavigation, { NavigationItem } from '~/components/account/MyPageNavigation.vue';
import { NorceUserTypes } from '~/constants/norceCodes';
import { useUserStore } from '~/store/user';
import { useUiStore } from '~/store/ui';
import { useGlobalContentStore } from '~/store/globalContent';
import useLinkReplacer from '~/composeables/useLinkReplacer';
const userStore = useUserStore();
const uiStore = useUiStore();
const globalContent = useGlobalContentStore();
const { accountUrl, salesUrl } = useLinkReplacer();

const props = defineProps<{
  type: 'account' | 'salestool',
  isDropdown: boolean,
  selected: string,
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const navigationList = ref<NavigationItem[]>([]);

const setNavigationList = (() => {
  if (props.type === 'salestool') {
    navigationList.value = [
      {
        type: 'overview',
        activeIcon: '/mypage/sheets-solid.svg',
        icon: '/mypage/sheets.svg',
        langKey: 'mypage.sales.dashboard',
        authorized: true,
      },
      {
        type: 'fastOrder',
        activeIcon: '/mypage/plus-solid.svg',
        icon: '/mypage/plus.svg',
        langKey: 'mypage.sales.fastOrder',
        authorized: true,
      },
      {
        type: 'orderHistory',
        activeIcon: '/mypage/box-w-smily-solid.svg',
        icon: '/mypage/box-w-smily.svg',
        langKey: 'mypage.sales.orderHistory',
        authorized: true,
      },
      {
        type: 'backorders',
        activeIcon: '/mypage/delivery-solid.svg',
        icon: '/mypage/delivery.svg',
        langKey: 'mypage.backorders',
        langKeyMobile: 'mypage.backorders.mobile',
        authorized: globalContent.getAllowBackorders,
      },
      {
        type: 'orderProposals',
        activeIcon: '/mypage/shopping-bag-heart-solid.svg',
        icon: '/mypage/shopping-bag-heart.svg',
        langKey: 'mypage.sales.orderProposal',
        authorized: true,
      },
      {
        type: 'customers',
        activeIcon: '/mypage/smiley-solid.svg',
        icon: '/mypage/smiley.svg',
        langKey: 'mypage.sales.customers',
        authorized: true,
      },
      {
        type: 'favorites',
        activeIcon: '/mypage/favorite-solid.svg',
        icon: '/mypage/favorite.svg',
        langKey: 'mypage.sales.favorites',
        authorized: true,
      },
      {
        type: 'carts',
        activeIcon: '/icons/cart-solid.svg',
        icon: '/icons/cart.svg',
        langKey: 'mypage.carts',
        authorized: true,
      },
      {
        type: 'onboarding',
        activeIcon: '/mypage/sales-tool.svg',
        icon: '/mypage/sales-tool.svg',
        langKey: 'mypage.sales.onboarding',
        authorized: true,
      },
      {
        type: 'settings',
        hidden: uiStore.isMobile,
        activeIcon: '/mypage/settings-solid.svg',
        icon: '/mypage/settings.svg',
        langKey: 'mypage.settings',
        authorized: true,
      },
    ];
  } else {
    navigationList.value = [
      {
        type: 'overview',
        activeIcon: '/mypage/sheets-solid.svg',
        icon: '/mypage/sheets.svg',
        langKey: 'mypage.overview',
        authorized: true,
      },
      {
        type: 'fastOrder',
        activeIcon: '/mypage/plus-solid.svg',
        icon: '/mypage/plus.svg',
        langKey: 'mypage.sales.fastOrder',
        authorized: [NorceUserTypes.CustomerSuccess].includes(userStore.userProfile.role.type),
      },
      {
        type: 'orderHistory',
        activeIcon: '/mypage/box-w-smily-solid.svg',
        icon: '/mypage/box-w-smily.svg',
        langKey: 'mypage.orderHistory',
        langKeyMobile: 'mypage.orderHistory.mobile',
        authorized: true,
      },
      {
        type: 'backorders',
        activeIcon: '/mypage/delivery-solid.svg',
        icon: '/mypage/delivery.svg',
        langKey: 'mypage.backorders',
        langKeyMobile: 'mypage.backorders.mobile',
        authorized: globalContent.getAllowBackorders,
      },
      {
        type: 'orderProposals',
        activeIcon: '/mypage/shopping-bag-heart-solid.svg',
        icon: '/mypage/shopping-bag-heart.svg',
        langKey: 'mypage.orderProposal',
        langKeyMobile: 'mypage.orderProposal.mobile',
        notificationKey: 'orderProposal',
        authorized: [ NorceUserTypes.CustomerSuccess, NorceUserTypes.Admin, NorceUserTypes.User ].includes(userStore.userProfile.role.type),
      },
      {
        type: 'invoices',
        activeIcon: '/mypage/order-completed-solid.svg',
        icon: '/mypage/order-completed.svg',
        langKey: 'mypage.invoices',
        notificationKey: 'invoice',
        authorized: [ NorceUserTypes.Finance, NorceUserTypes.Admin, NorceUserTypes.CustomerSuccess ].includes(userStore.userProfile.role.type),
      },
      {
        type: 'favorites',
        activeIcon: '/mypage/favorite-solid.svg',
        icon: '/mypage/favorite.svg',
        langKey: 'mypage.favorites',
        authorized: !userStore.isCustomerSuccessUser,
      },
      {
        type: 'carts',
        activeIcon: '/icons/cart-solid.svg',
        icon: '/icons/cart.svg',
        langKey: 'mypage.carts',
        authorized: true,
      },
      {
        type: 'onboarding',
        activeIcon: '/mypage/sales-tool.svg',
        icon: '/mypage/sales-tool.svg',
        langKey: 'mypage.user.onboarding',
        authorized: !userStore.isCustomerSuccessUser,
      },
      {
        type: 'settings',
        //hidden: uiStore.isMobile,
        activeIcon: '/mypage/settings-solid.svg',
        icon: '/mypage/settings.svg',
        langKey: 'mypage.settings',
        authorized: true,
      },
    ];
  }
});

setNavigationList();

const setShowPage = (page: string) => {
  if (page === 'onboarding') {
    uiStore.setShowModalBg(true);
    userStore.userProfile.hasSeenOnboarding = false;
  } else if (props.type === 'salestool') {
    navigateTo(salesUrl(page));
  } else {
    navigateTo(accountUrl(page));
  }

  emit('close');
};

watch(() => userStore.userProfile.role.type, () => {
  setNavigationList();
});

</script>

<style scoped lang="postcss">
</style>
